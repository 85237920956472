import "~/components/shared/SEOBlock.css";
import "./ResultsSEO.css";

export default function ResultsSEO() {
  return (
    <>
      <div class="seo-block" data-test="seo-block">
        <div class="content-part">
          <h1>Avec Cogedim, trouvez le bien neuf de vos rêves</h1>
          <div class="text">
            <p>
              Trouver le promoteur qui saura vous accompagner, vous conseiller
              et vous guider tout au long de votre projet est l’une des étapes
              les plus importantes dans la réussite de votre investissement
              immobilier. Cogedim s’engage à vous fournir un accompagnement
              personnalisé, de la recherche du bien jusqu’à plusieurs années
              après la livraison, pour vous faire vivre une expérience agréable.
            </p>
            <h2>Les avantages d’investir dans l’immobilier neuf</h2>
            <p>
              L’immobilier neuf est un marché sur lequel les risques sont assez
              faibles et où les rendements s’avèrent particulièrement
              intéressants. C’est d’ailleurs le placement préféré des Français.
              Aujourd’hui, acquérir un logement neuf offre des avantages
              incomparables : sécurité, frais réduits, dispositifs fiscaux
              intéressants et plus encore. Découvrez en 4 points, les avantages
              de l’investissement dans le neuf.
            </p>
            <h3>Les frais de notaire réduits</h3>
            <p>
              L’acquéreur d’un bien immobilier, neuf ou ancien, doit s’acquitter
              des « frais de notaire » ou « frais d’acquisition », en plus du
              prix du logement. Ces frais sont particulièrement faibles
              lorsqu’il s’agit d’un bien neuf : ils représentent 2 à 3% du prix
              d’achat, alors qu’ils peuvent s’élever à 8% pour un logement
              ancien. Une économie intéressante et non négligeable.
            </p>
            <p>
              Les frais d’acquisition se décomposent de la manière suivante :
            </p>
            <ul>
              <li>80% sont des impôts et taxes ;</li>
              <li>
                10% correspondent aux frais de débours engendrés par la
                rémunération d’intervenants externes (experts, géomètres, etc.)
                et le paiement de documents tels que la publication de vente ;
              </li>
              <li>
                10% concernent les émoluments, autrement dit la rémunération
                réelle du notaire. Ces derniers sont proportionnels au prix de
                vente, sur lequel est appliqué un pourcentage dégressif.
              </li>
            </ul>
            <h3>Les aides de l’Etat dédiées à l’achat dans le neuf</h3>
            <p>
              Le prêt à taux zéro (PTZ) est un crédit gratuit en faveur de
              l’investissement immobilier dans le neuf qui aide les
              primo-accédants à acquérir leur résidence principale. Il peut
              financer jusqu’à 40% du montant du bien. Il est accessible aux
              particuliers de classe moyenne (il concerne plus de 80% des
              ménages grâce à des plafonds de ressource très élevés) qui
              achètent un bien immobilier pour la première fois. Condition
              importante, le logement doit être la résidence principale de
              l’acquéreur, ce qui signifie qu’il doit être occupé au moins 8
              mois par an. Ce prêt peut donc faciliter l’achat d’un logement
              vendu sur plans, d’un bien neuf jamais habité, d’un terrain ou la
              construction d’une maison.
            </p>
            <h3>Dispositifs fiscaux</h3>
            <p>
              Plusieurs dispositifs permettant des optimisations fiscales ont
              été mis en place par l’État afin de favoriser l’investissement
              immobilier.
            </p>
            <h4>La loi Pinel 2019</h4>
            <p>
              C’est un dispositif qui s’applique à des logements neufs, dont la
              valeur n’excède pas 300 000 euros et qui sont situés dans des
              zones éligibles. Il donne accès à une réduction d’impôt allant de
              12 à 21% en fonction de plusieurs conditions (situation fiscale,
              prix de l’achat immobilier, modalité de prêt et engagement de
              location). Le propriétaire qui achète un logement en profitant du
              dispositif Pinel s’engage à le mettre en location pour une durée
              de 6 à 12 ans, à ne pas en faire sa résidence principale et à ne
              pas le vendre pendant cette période d’engagement.
            </p>
            <h3>Investissement confortable et sécurisé</h3>
            <p>
              Plus qu’une simple question de goût, faire le choix du neuf, c’est
              investir dans un bien immobilier sécurisant, confortable et
              économique.
            </p>
            <p>
              D’abord, vivre dans un logement neuf permet de choisir la
              disposition, l’ameublement et la décoration. C’est donc pouvoir
              faire le choix d’équipements qui amélioreront le quotidien et qui
              permettent de réaliser des économies : chaudières dernières
              générations, robinetterie économe, volets intelligents qui se
              ferment en fonction de la température extérieure ou lumières
              programmables. Sans parler de la connectivité qui permet de suivre
              la consommation et de régler le chauffage ou la luminosité à
              distance.
            </p>
            <p>
              Aussi, avec des normes et des exigences de constructions qui ne
              cessent d’évoluer, opter pour le neuf, c’est l’assurance de ne pas
              avoir à réaliser des travaux à courte échéance. C’est également
              profiter de hautes performances d’isolation thermique et phonique
              pour un confort idéal.
            </p>
            <h2>
              Pourquoi acheter son logement auprès d’un promoteur immobilier ?
            </h2>
            <p>
              Le promoteur est un expert du marché immobilier. Afin de
              comprendre pourquoi sa présence facilite le parcours qui mène à la
              concrétisation de vos projets, il est important de mieux connaître
              son rôle.
            </p>
            <h3>Les missions du promoteur immobilier</h3>
            <p>
              Véritable spécialiste de l’immobilier, le promoteur est présent
              sur toutes les étapes de la construction d’un bâtiment, qu’il soit
              destiné à l’habitat ou au commerce. En amont, il procède à la
              recherche du terrain, réalise l’étude financière, conçoit les
              plans, et entreprend l’ensemble des démarches administratives. En
              tant que maître d’œuvre, il supervise ensuite le chantier du début
              à la livraison.
            </p>
            <h3>Les avantages de passer par un promoteur</h3>
            <h4>Profiter du savoir d’un professionnel</h4>
            <p>
              Le promoteur maîtrise les marchés immobiliers aux niveaux local et
              national : l’offre et la demande, les opportunités, les codes et
              les contraintes. Son rôle premier est de proposer à ses clients le
              meilleur projet en fonction de leurs attentes, de leurs besoins et
              de leurs capacités de financement. D’ailleurs, autre avantage de
              choix : il est au fait de toutes les aides et les différents
              dispositifs qui faciliteront l’acquisition (PTZ, subventions
              diverses, etc.). En fonction du profil des futurs propriétaires,
              il peut leur permettre de réaliser des économies importantes.
            </p>
            <h4>Cogedim, une expérience unique</h4>
            <p>
              Investir, surtout quand pour la première fois, peut être une
              grande source de stress, voire de véritable angoisse. Cogedim vous
              accompagne pour franchir toutes les étapes de l’achat immobilier
              en toute sérénité. À partir du premier rendez-vous et jusqu’à
              plusieurs années après la remise des clés, nous sommes à vos côtés
              et nous répondons à tous vos besoins. Acheter un logement sur
              plans nécessite de pouvoir bénéficier d’outils de projection
              performants afin de s’assurer d’une conformité exacte du logement
              avant et après construction. Cogedim met à la disposition de ses
              clients un ensemble d’outils tels que les plans 3D, les visites
              virtuelles, et même une visite immersive de logements reproduits
              en taille réelle dans nos Stores Cogedim.
            </p>
            <h4>
              L’assurance de recevoir un produit de qualité sans frais
              additionnels
            </h4>
            <p>
              Faire appel à un promoteur immobilier, c’est aussi la certitude
              que son budget sera respecté et l’assurance de recevoir un
              logement neuf, conforme aux dernières normes en vigueur.
            </p>
            <h2>
              Labels, certifications et réglementations de l’immobilier neuf
            </h2>
            <p>
              Avant de se lancer dans le projet d’achat d’un logement neuf, il
              est important de se renseigner sur les différents labels et
              certifications que l’on il peut bénéficier. Gages de qualité et
              d’économie d’énergie, certains n’existent d’ailleurs que dans le
              secteur de l’immobilier. Il est donc intéressant de pouvoir les
              comprendre. Tour d’horizon.
            </p>
            <ul>
              <li>
                La réglementation thermique 2012 (RT 2012) encadre la
                consommation énergétique des maisons et bâtiments neufs sur
                l’éclairage, la ventilation, la production d’eau chaude
                sanitaire et le chauffage. Elle a pour but de réduire la
                consommation d’énergie des logements et par conséquent
                l’émission de gaz à effet de serre, tout en assurant l’isolation
                et le confort thermique ;
              </li>
              <li>
                La réglementation thermique 2020 (RT 2020) entrée en vigueur en
                2021, concerne les bâtiments neufs. C’est une « mise à jour » de
                la norme RT 2012 qui a pour objectif de faire en sorte que toute
                nouvelle construction produise davantage d’énergie qu’elle n’en
                consomme ;
              </li>
              <li>
                Les labels HPE (haute performance énergétique) et THPE (très
                haute performance énergétique) indiquent une consommation
                d’énergie primaire de respectivement 10% et 20% inférieure à la
                RT 2012 ;
              </li>
              <li>
                La certification NF Habitat / NF Habitat HQE a été créée en 2015
                par un organisme indépendant dans le but de simplifier la
                compréhension des particuliers. Délivrée par des consommateurs
                et des professionnels de l’énergie et de la construction, elle
                met en avant les performances énergétiques en se basant sur un
                cahier des charges très strict concernant la qualité et les
                performances techniques des bâtiments, mais aussi des critères
                de service et d’information ;
              </li>
              <li>
                Le label Effinergie+ est délivré par l’association Effinergie.
                Il prend en compte la performance énergétique des programmes
                immobiliers neufs, mais aussi l’efficacité des systèmes de
                ventilation et l’étanchéité de l’air ;
              </li>
            </ul>
            <h3>Les avantages de choisir un logement neuf certifié</h3>
            <p>
              Que ce soit dans le cadre d’un investissement locatif ou pour une
              résidence principale, l’achat d’un logement neuf labellisé ou
              certifié permet de profiter d’un lieu de vie peu gourmand en
              énergie. Par conséquent, ce sont des économies garanties sur les
              factures de gaz et d’électricité à la fin du mois. En tant que
              propriétaire, c’est aussi un atout intéressant qui pourra être
              valorisé dans le cas d’une revente. Enfin, investir dans un
              logement neuf certifié encourage le recours à ce type de
              construction plus respectueuse de l’environnement, moins polluante
              et plus verte. Aujourd’hui, en France, les programmes immobiliers
              neufs ne demandent qu’à être soutenus.
            </p>
            <h2>Comment réussir son investissement dans l’immobilier neuf ?</h2>
            <p>
              C’est la question que tout futur propriétaire de logement neuf se
              pose : comment faire en sorte que son investissement rapporte ou
              qu’il constitue un placement fiable ?
            </p>
            <h3>Emplacement, surface : bien choisir son logement</h3>
            <p>
              La situation géographique d’un logement a une influence directe
              sur sa valeur. C’est pourquoi opter pour une ville très prisée
              pour investir dans l’immobilier peut revenir très cher. Il
              convient parfois de choisir des localités proches affichant des
              prix souvent plus bas. Aussi, que la gestion du logement soit
              assurée par une entreprise spécifique ou non, il est recommandé
              d’investir dans un secteur relativement proche de chez soi afin de
              pouvoir consulter les prix pratiqués, les spécificités du marché,
              les aides et dispositifs régionaux, etc.
            </p>
            <p>
              La surface est un des critères essentiels d’un achat immobilier.
              Pour une meilleure rentabilité, il est souvent plus intéressant
              d’investir dans une petite surface : prix d’achat moins élevé,
              prix au m2 plus important au moment de la mise en location, biens
              particulièrement recherchés dans les grandes métropoles
              françaises.
            </p>
            <h3>Tenir compte de la fiscalité</h3>
            <p>
              Être parfaitement renseigné sur les différentes dispositions
              fiscales qui encadrent l’achat d’un bien neuf dans l’optique de le
              louer est primordial. Il est en effet possible que des aides ou
              des réductions d’impôts soient accessibles si la situation
              personnelle et économique de l’investisseur répond aux conditions
              attendues, ce qui favoriserait la rentabilité de l’investissement.
            </p>
            <h3>Assurer la gestion locative</h3>
            <p>
              Une fois que le logement est acquis, il reste encore quelques
              étapes avant de pouvoir bénéficier d’un revenu complémentaire.
              Pour le mettre en location, il existe deux possibilités : assurer
              la gestion soi-même ou confier cette tâche à un professionnel
              comme Altarea Gestion Immobilière. Un investissement locatif
              nécessite du temps, mais des solutions existent. Il est nécessaire
              d’en avoir connaissance pour prendre la meilleure décision en
              fonction de sa situation personnelle. Cogedim offre à l’ensemble
              de ses clients la possibilité de bénéficier d’une gestion locative
              avantageuse et complète pour un placement sans aucun soucis de
              gestion.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
